const newsLetterData = [
    {
        date: "January 19, 2024",
        srcOfImage: "/assets/newsletter/JanuaryNewsletter2024.png",
        srcOfNewsLetter: "/assets/newsletter/JanuaryNewsletter2024.pdf",
        year:"2024"       
    },
    {
        date: "February 10, 2024",
        srcOfImage: "/assets/newsletter/FebruaryNewsletter2024.png",
        srcOfNewsLetter: "/assets/newsletter/FebruaryNewsletter2024.pdf",
        year:"2024"
    },
    {
        date: "March 16, 2024",
        srcOfImage: "/assets/newsletter/MarchNewsletter2024.png",
        srcOfNewsLetter: "/assets/newsletter/MarchNewsletter2024.pdf",
        year:"2024",
    },
    {
        date: "April 24, 2024",
        srcOfImage: "/assets/newsletter/AprilNewsletter2024.png",
        srcOfNewsLetter: "/assets/newsletter/AprilNewsletter2024.pdf",
        year:"2024"
    },
    {
        date: "September 18, 2024",
        srcOfImage: "/assets/newsletter/SeptemberNewsletter2024.png",
        srcOfNewsLetter: "/assets/newsletter/SeptemberNewsletter2024.pdf",
        year:"2024",
    },
    {
        date: "October 23, 2024",
        srcOfImage: "/assets/newsletter/OctoberNewsletter2024.png",
        srcOfNewsLetter: "/assets/newsletter/OctoberNewsletter2024.pdf",
        year:"2024"
    },
    {
        date: "January 13, 2025",
        srcOfImage: "/assets/newsletter/JanuaryNewsletter2025.png",
        srcOfNewsLetter: "/assets/newsletter/JanuaryNewsletter2025.pdf",
        year:"2025"
    },
]
export default newsLetterData;